import { FilterFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form, Radio, Space, Tag, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  DEFAULT_DATE_FORMAT,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import CreateSheetModal from './components/CreateSheetModal';
import ProjectModal from './components/ProjectModal';
import { PROJECTS } from './graphql/Queries';

const initialFinalFilter = {
  status: '',
};

function Projects(props) {
  const {
    location: { state },
  } = props;
  const [form] = Form?.useForm();
  const history = useHistory();
  const [initialFilter, setInitialFilter] = useState({
    skip: state?.skip || 0,
    searchString: state?.searchString || '',
    sortOrder: state?.sortOrder || 'DESC',
    sortField: state?.sortField || 'createdOn',
    status: state?.status || null,
  });
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: state?.currentPage || 1,
    pageSize: state?.pageSize || LIMIT,
  });
  const activeSort = initialFilter?.sortOrder === 'DESC' ? 'descend' : 'ascend';
  const [showModal, setShowModal] = useState(false);
  const [showCreateSheetModal, setShowCreateSheetModal] = useState(false);
  const [projectRecord, setProjectRecord] = useState();
  const [checked, setChecked] = useState(false);
  const [statusFilterVisible, setStatusFilterVisible] = useState(false);
  const [finalFilter, setFinalFilter] = useState(initialFinalFilter);
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.PROJECTS,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });

  useEffect(() => {
    if (history?.location?.search) {
      message.info({
        content: decodeURIComponent(
          history?.location?.search?.split('message=')?.[1],
        ),
        duration: 2,
        onClose: () => history?.push(`${ROUTES_MODULES_KEY?.PROJECTS}`),
      });
    }
  }, [history]);

  const [executeQuery, { loading, data }] = useLazyQuery(PROJECTS, {
    fetchPolicy: 'network-only',
    onError() {},
    refetchQueries: [
      {
        query: PROJECTS,
        variables: {
          filters: {
            ...initialFilter,
            limit: paginationFilter?.pageSize,
          },
        },
      },
    ],
  });

  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
        },
      },
    });
  }, []);

  const handleSearch = (value) => {
    setInitialFilter({
      ...initialFilter,
      skip: 0,
      searchString: value?.trim(),
    });
    setPaginationFilter({ ...paginationFilter, currentPage: 1 });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          skip: 0,
          limit: paginationFilter?.pageSize,
          searchString: value?.trim(),
        },
      },
    });
  };

  const handleAddProject = () => {
    setProjectRecord(null);
    setShowModal(true);
  };

  const handleCreateSheet = () => {
    setShowCreateSheetModal(true);
  };

  const onChange = (pagination, filters, sorter) => {
    const { current: currentPage, pageSize } = pagination;
    const { order, field } = sorter;
    const filter = {
      skip: currentPage ? (currentPage - 1) * pageSize : 0,
      sortOrder: order === 'descend' ? 'DESC' : 'ASC',
      sortField: field,
    };
    setInitialFilter({ ...initialFilter, ...filter });
    setPaginationFilter({ ...paginationFilter, currentPage, pageSize });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          skip: filter?.skip,
          limit: pageSize,
          sortField: filter?.sortField,
          sortOrder: filter?.sortOrder,
        },
      },
    });
  };

  const handleReset = (prop) => {
    form?.resetFields();
    setChecked(!checked);
    setInitialFilter({
      ...initialFilter,
      status: null,
    });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
          status: null,
        },
      },
    });
    const updatedFinalFilter = {
      ...finalFilter,
      status: prop === 'status' ? null : finalFilter?.status,
    };
    setFinalFilter(updatedFinalFilter);
  };
  const handleStatus = (e) => {
    setInitialFilter({
      ...initialFilter,
      status: e?.target?.value,
    });
  };
  const handleFilter = () => {
    setInitialFilter({
      ...initialFilter,
      skip: 0,
    });
    setPaginationFilter({ ...paginationFilter, currentPage: 1 });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          skip: 0,
          limit: paginationFilter?.pageSize,
        },
      },
    });
    setFinalFilter({
      status: initialFilter?.status,
    });
    setStatusFilterVisible(false);
  };

  const getProjectFilterProps = () => ({
    filterDropdownOpen: statusFilterVisible,
    onFilterDropdownOpenChange: (visible) => setStatusFilterVisible(visible),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Form name="control-ref" form={form} layout="vertical">
            <Form.Item name="status" valuePropName="checked">
              <Radio.Group
                defaultValue={initialFilter?.status}
                onChange={handleStatus}
                value={initialFilter.status}
              >
                <Space direction="vertical">
                  <Radio value="ACTIVE">Active</Radio>
                  <Radio value="INACTIVE">Inactive</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset('status');
                setStatusFilterVisible(false);
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    render: (text, record) => (
      <Link
        to={{
          pathname: `${ROUTES_MODULES_KEY?.PROJECTS}/${record?.id}`,
          state: {
            initialFilter: { ...initialFilter },
            paginationFilter: { ...paginationFilter },
          },
        }}
      >
        <Tag
          className="project-table-tags"
          color={record?.status?.length > 6 ? 'error' : 'success'}
        >
          {text?.[0]?.toUpperCase() + text?.slice(1)?.toLowerCase()}
        </Tag>
      </Link>
    ),
    filterIcon: () => (
      <FilterFilled
        className={
          finalFilter?.status || initialFilter?.status
            ? 'filter-filled-data'
            : ''
        }
      />
    ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: initialFilter?.sortField === 'name' ? activeSort : null,
      className: 'link-custom-width',
      render: (value, record) => (
        <Link
          to={{
            pathname: `${ROUTES_MODULES_KEY?.PROJECTS}/${record?.id}`,
            state: {
              initialFilter: { ...initialFilter },
              paginationFilter: { ...paginationFilter },
            },
          }}
        >
          {record?.name}
        </Link>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      className: 'link-custom-width',
      render: (value, record) => (
        <Link
          to={{
            pathname: `${ROUTES_MODULES_KEY?.PROJECTS}/${record?.id}`,
            state: {
              initialFilter: { ...initialFilter },
              paginationFilter: { ...paginationFilter },
            },
          }}
        >
          {record?.clientName}
        </Link>
      ),
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      defaultSortOrder:
        initialFilter?.sortField === 'createdOn' ? activeSort : null,
      sortDirections: ['descend'],
      sorter: true,
      className: 'link-custom-width',
      render: (value, record) => (
        <Link
          to={{
            pathname: `${ROUTES_MODULES_KEY?.PROJECTS}/${record?.id}`,
            state: {
              initialFilter: { ...initialFilter },
              paginationFilter: { ...paginationFilter },
            },
          }}
        >
          {dayjs(record?.createdOn)?.format(DEFAULT_DATE_FORMAT)}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filterMultiple: false,
      ...getProjectFilterProps(),
      className: 'link-custom-width',
    },
  ];
  const handleRowClick = (record) => {
    history?.push({
      pathname: `${ROUTES_MODULES_KEY?.PROJECTS}/${record?.id}`,
      state: {
        initialFilter: { ...initialFilter },
        paginationFilter: { ...paginationFilter },
      },
    });
  };

  const handleBeforeUnload = () => {
    history.push({
      pathname: history.location.pathname,
      state: null,
    });
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div className="table-card-page">
        <Card
          className="ant-body-scroll "
          title="Projects"
          extra={
            <div className="project-filter-right">
              <div className="project-filter">
                {isCreatePermission && (
                  <>
                    <Button key="1" type="primary" onClick={handleCreateSheet}>
                      Create Sheet
                    </Button>
                    <Button key="2" type="primary" onClick={handleAddProject}>
                      Add Project
                    </Button>
                  </>
                )}
                <SearchComponent
                  getData={handleSearch}
                  name="project or client"
                  {...(state?.searchString && {
                    defaultValue: state?.searchString,
                  })}
                />
              </div>
            </div>
          }
        >
          <div className="card-body-wrapper">
            <CommonTable
              size="small"
              columns={columns}
              dataSource={data?.projects?.projects}
              onChange={onChange}
              rowKey={(obj) => obj?.id}
              loadingData={loading}
              bordered
              paginationConfig={{
                current: paginationFilter?.currentPage,
                total: data?.projects?.count,
                pageSize: paginationFilter?.pageSize,
              }}
              onRow={(record) => ({ onClick: () => handleRowClick(record) })}
              rowClassName="project-table-row"
              className="project-table"
            />
            <ProjectModal
              projectRecord={projectRecord}
              setProjectRecord={setProjectRecord}
              executeQuery={executeQuery}
              show={showModal}
              close={() => setShowModal(false)}
            />
            {showCreateSheetModal && (
              <CreateSheetModal
                show={showCreateSheetModal}
                close={() => setShowCreateSheetModal(false)}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

export default Projects;
