import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BACKEND_YEAR_FORMAT,
  CURRENT_YEAR,
  DEFAULT_DATE_FORMAT,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import HolidayModal from './components/HolidayModal';
import { DELETE_HOLIDAY } from './graphql/Mutations';
import { HOLIDAYS } from './graphql/Queries';

function Holiday() {
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: 1,
    pageSize: LIMIT,
  });
  const [initialFilter, setInitialFilter] = useState({
    skip: 0,
    year: CURRENT_YEAR,
    search: '',
    orderBy: 'startDate',
    order: 'DESC',
  });
  const [holidayId, setHolidayId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [holidayRecord, setHolidayRecord] = useState();
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.HOLIDAYS,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.HOLIDAYS,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });
  const isDeletePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.HOLIDAYS,
    allowedPermissions: [PERMISSION_OBJECT?.delete],
  });
  const [executeQuery, { loading, data }] = useLazyQuery(HOLIDAYS, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const dataSource = data?.holidaysAdmin?.holidays || [];

  const [deleteHoliday] = useMutation(DELETE_HOLIDAY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      executeQuery({
        variables: {
          filter: {
            ...initialFilter,
            skip: 0,
            limit: paginationFilter?.pageSize,
          },
        },
      });
      setPaginationFilter({
        ...paginationFilter,
        currentPage: 1,
      });
    },
    onError: (error) => message?.error(`Error! ${error?.message}`),
  });

  useEffect(() => {
    executeQuery({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
        },
      },
    });
  }, []);

  const handleSearch = debounce((value) => {
    setPaginationFilter({
      ...paginationFilter,
      currentPage: 1,
    });
    setInitialFilter({ ...initialFilter, search: value?.toString()?.trim() });
    executeQuery({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
          search: value?.toString()?.trim(),
        },
      },
    });
  }, 700);

  const handleYearChange = (value) => {
    const updatedValue = parseInt(
      dayjs(value)?.format(BACKEND_YEAR_FORMAT),
      10,
    );
    setInitialFilter({ ...initialFilter, year: updatedValue });
    executeQuery({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
          year: updatedValue,
        },
      },
    });
  };

  const confirm = () => {
    deleteHoliday({
      variables: {
        data: {
          id: holidayId,
        },
      },
    });
  };
  const handleDeleteClick = (record) => {
    setHolidayId(record?.id);
  };

  const handleEditClick = (record) => {
    setHolidayRecord(record);
    setShowModal(true);
  };

  const handleAddHoliday = () => {
    setHolidayRecord(null);
    setShowModal(true);
  };
  // filters changed to _filters to disable eslint error as it is not being used
  const onChange = (pagination, _filters, sorter) => {
    const { current: currentPage, pageSize } = pagination;
    const { order } = sorter;
    const filter = {
      skip: currentPage ? (currentPage - 1) * pageSize : 0,
      order: order === 'descend' ? 'DESC' : 'ASC',
    };
    setInitialFilter({ ...initialFilter, ...filter });
    setPaginationFilter({ ...paginationFilter, currentPage, pageSize });
    executeQuery({
      variables: {
        filter: {
          ...initialFilter,
          skip: filter?.skip,
          limit: pageSize,
          order: filter?.order,
        },
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      align: 'center',
      render: (_, record) =>
        dayjs(record?.startDate)?.format(DEFAULT_DATE_FORMAT),
    },
    {
      title: 'End Date',
      key: 'endDate',
      sorter: true,
      align: 'center',
      render: (_, record) =>
        record?.startDate === record?.endDate
          ? '-'
          : dayjs(record?.endDate)?.format(DEFAULT_DATE_FORMAT),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      align: 'center',
      render: (_, record) => record?.description || '-',
    },
    {
      title: 'Recurring',
      key: 'recurring',
      align: 'center',
      className: 'link-custom-width',
      render: (_, record) => (
        <Tag
          className="holiday-table-tags"
          color={record?.recurring ? 'success' : 'error'}
        >
          {record?.recurring ? 'Yes' : 'No'}
        </Tag>
      ),
    },
    ...(isUpdatePermission || isDeletePermission
      ? [
          {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 120,
            render: (_, record) => (
              <Space size="middle">
                {isUpdatePermission && (
                  <Tooltip placement="bottom" title={<span>Edit Holiday</span>}>
                    <Button type="text" onClick={() => handleEditClick(record)}>
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                )}
                {isDeletePermission && (
                  <Popconfirm
                    title="Delete Holiday"
                    description="Are you sure to delete this Holiday?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      placement="bottom"
                      title={<span>Delete Holiday</span>}
                    >
                      <Button
                        danger
                        type="text"
                        onClick={() => handleDeleteClick(record)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                )}
              </Space>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Holidays"
        extra={
          <>
            <div className="holidays-sidebar">
              <div className="holiday-filter-right">
                {isCreatePermission && (
                  <Button key="1" type="primary" onClick={handleAddHoliday}>
                    Add Holiday
                  </Button>
                )}
                <SearchComponent getData={handleSearch} name="holiday" />
              </div>
            </div>
          </>
        }
      >
        <div className="holiday-filter-left p-8">
          <strong className="mr-8">
            Select the year for which you want to add or check holidays:
          </strong>
          <DatePicker
            className="holiday-year-picker"
            defaultValue={dayjs()}
            format="YYYY"
            onChange={handleYearChange}
            picker="year"
            allowClear={false}
          />
        </div>
        <CommonTable
          className="holiday-common-table"
          size="small"
          loadingData={loading}
          columns={columns}
          dataSource={dataSource}
          rowKey={(obj) => obj?.id}
          bordered
          onChange={onChange}
          paginationConfig={{
            current: paginationFilter?.currentPage,
            total: data?.holidays?.count,
            pageSize: paginationFilter?.pageSize,
          }}
        />

        {showModal && (
          <HolidayModal
            holidayRecord={holidayRecord}
            executeQuery={executeQuery}
            setHolidayRecord={setHolidayRecord}
            show={showModal}
            close={() => setShowModal(false)}
            initialFilter={initialFilter}
            setPaginationFilter={setPaginationFilter}
            paginationFilter={paginationFilter}
          />
        )}
      </Card>
    </div>
  );
}

export default Holiday;
